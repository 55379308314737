<template>
    <transition name="fade">
        <div>
            <div class="modal-backdrop show"></div>
            <div class="modal" tabindex="-1" style="display:block">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="d-flex align-items-center">
                                <div class="text-center" style="width:50px; height:50px;">
                                    <h1><i class="bi bi-calendar3"></i></h1>
                                </div>
                                <h4 class="modal-title ms-3">
                                    {{ modal_title }}
                                </h4>
                            </div>
                            <button type="button" class="btn-close" @click="close"></button>
                        </div>
                        <div class="modal-body">
                            <inline-loader v-if="loading"></inline-loader>
                            <template v-else>
                                <div class="d-flex justify-content-end">
                                    <div class="text-end me-2">
                                        <button-search
                                            icon="bi-skip-backward-fill"
                                            text=""
                                            size="btn-sm"
                                            color="btn-outline-primary"
                                            @click="subMonth"
                                        ></button-search>
                                    </div>
                                    <div class="text-center align-self-center">
                                        <select
                                            v-model="date"
                                            class="form-select form-select-sm"
                                            @change="fetchPartnerReservedScheduleList()"
                                        >
                                            <template v-for="date in calendar_dates" :key="date">
                                                <option :value="date.key">{{ date.label }}</option>
                                            </template>
                                        </select>
                                    </div>
                                    <div class="text-start ms-2">
                                        <button-search
                                            icon="bi-skip-forward-fill"
                                            text=""
                                            size="btn-sm"
                                            color="btn-outline-primary"
                                            @click="addMonth"
                                        ></button-search>
                                    </div>
                                </div>
                                <template v-if="invitation_schedules.length">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th class="col-3">日付</th>
                                                <th class="col-13">スケジュール名</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="invitation_schedule in invitation_schedules" :key="invitation_schedule">
                                                <tr>
                                                    <td>{{ invitation_schedule.invitation.invitation_date_display }}</td>
                                                    <td>
                                                        <template v-if="invitation_schedule.schedules.length">
                                                            <template v-for="schedule in invitation_schedule.schedules" :key="schedule">
                                                                <div>
                                                                    <span style="font-size:.9rem;"><i class="bi me-1" :class="schedule.schedule_icon"></i></span>
                                                                    {{ schedule.schedule_name_display }}
                                                                </div>
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            スケジュール未定
                                                        </template>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                        <tfoot></tfoot>
                                    </table>
                                </template>
                                <template v-else>
                                    <alert-no-record
                                        class="mt-4"
                                        text="お願いしている予定はありません"
                                    ></alert-no-record>
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import AlertNoRecord from '@/components/tools/AlertNoRecord';
import Schedule from '@/models/entities/schedule';
import Invitation from '@/models/entities/invitation';
import InlineLoader from '@/components/tools/InlineLoader';
import ButtonSearch from '@/components/buttons/ButtonSearch';

export default {
    name: 'PartnerReservedScheduleModal',
    components: {
        AlertNoRecord,
        InlineLoader,
        ButtonSearch,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    props: {
        photographer_id: {
            type: Number,
            default: null
        },
        modal_title: {
            type: String,
            default: ''
        },
    },
    emits: [
        'close',
    ],
    data() {
        return {
            loading: 0,

            invitation_schedules: [],
            date: this.getThisMonth(),
        }
    },
    mounted() {
        this.fetchPartnerReservedScheduleList();
    },
    watch: {

    },
    computed: {
        calendar_dates() {
            // 一年前
            let start = new Date(this.date);
            start.setDate(1);
            start.setFullYear(start.getFullYear() - 1);
            // 一年後
            let end = new Date(this.date);
            end.setDate(1);
            end.setFullYear(end.getFullYear() + 1);

            // 間の日付を取得(１ヶ月間隔)
            let dates = [];
            for (let date = start; date <= end; date.setMonth(date.getMonth()+1)) {
                let target_date = new Date(date.valueOf());

                let calendar_date = {
                    key: `${target_date.getFullYear()}-${("00" + (target_date.getMonth()+1)).slice(-2)}-${("00" + target_date.getDate()).slice(-2)}`,
                    label: `${target_date.getFullYear()}年${("00" + (target_date.getMonth()+1)).slice(-2)}月`
                }
                dates.push(calendar_date);
            }

            return dates;
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },

        // 今月1日を取得
        getThisMonth() {
            let dt = new Date();
            dt.setDate(1);
            let y = dt.getFullYear();
            let m = ("00" + (dt.getMonth()+1)).slice(-2);
            let d = ("00" + dt.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            return result;
        },

        // 前の月へ
        subMonth() {
            let date = new Date(this.date);
            date.setMonth(date.getMonth() - 1);
            let y = date.getFullYear();
            let m = ("00" + (date.getMonth()+1)).slice(-2);
            let d = ("00" + date.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            this.date = result;
            this.fetchPartnerReservedScheduleList();
        },
        // 次の月へ
        addMonth() {
            let date = new Date(this.date);
            date.setMonth(date.getMonth() + 1);
            let y = date.getFullYear();
            let m = ("00" + (date.getMonth()+1)).slice(-2);
            let d = ("00" + date.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            this.date = result;
            this.fetchPartnerReservedScheduleList();
        },

        // 応援カメラマン確保済み日程一覧取得
        fetchPartnerReservedScheduleList() {
            this.loading++;

            this.invitation_schedules.splice(0);

            this.$http.get('/partners/reserved', {
                params: {
                    'photographer_id': this.photographer_id,
                    'date': this.date
                }
            })
            .then(response => {
                for (let row of response.data) {
                    let invitation_schedule = {
                        invitation: null,
                        schedules: []
                    };

                    invitation_schedule.invitation = new Invitation(row.invitation);
                    if (row.schedules.length) {
                        for (let schedule of row.schedules) {
                            invitation_schedule.schedules.push(new Schedule(schedule));
                        }
                    }
                    this.invitation_schedules.push(invitation_schedule);
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
