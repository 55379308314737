<template>
    <page-title
        title="応援カメラマン一覧"
        icon="bi-people-fill"
    >
        <button-go-create
            :to="{name: 'PartnerAdd'}"
        ></button-go-create>
    </page-title>

    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label>氏名</label>
                <form-input
                    v-model="condition.photographer_name"
                ></form-input>
            </div>
            <div class="form-group col-md-4">
                <label>ランク</label>
                <form-select
                    v-model="condition.partner_rank"
                    :options="partner_ranks"
                    empty_option="-- 全て --"
                ></form-select>
            </div>
            <div class="col-3">
                <button-search
                    @click="search()"
                    :disabled="loading"
                ></button-search>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <template v-else>
            <template v-if="partners.length">
                <table class="table table-striped mb-4">
                    <thead>
                        <tr class="table-primary">
                            <th class="col-md-6">氏名</th>
                            <th class="col-md-4">略称</th>
                            <th class="col-md-5">電話番号</th>
                            <th class="col-md-4">ランク</th>
                            <th class="col-md-5"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="partner in partners" :key="partner">
                            <tr>
                                <td class="">{{ partner.photographer_name }}</td>
                                <td class="">{{ partner.shortened_name }}</td>
                                <td class="">{{ partner.tel }}</td>
                                <td class="">{{ partner.partner_rank_display }}</td>
                                <td class="text-center">
                                    <button-go-update
                                        :to="{name: 'PartnerEdit', params: {id: partner.photographer_id}}"
                                    ></button-go-update>
                                    <button-go-view
                                        class="ms-5"
                                        text="予定確認"
                                        icon="bi-box-arrow-up-left"
                                        @click="openParentReservedScheduleModal(partner.photographer_id)"
                                    ></button-go-view>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <tfoot></tfoot>
                </table>
                <paginator :meta="paginator" @move="search"></paginator>
            </template>
            <alert-no-record v-else></alert-no-record>
        </template>
    </section>

    <!-- 応援カメラマン予約確認モーダル -->
    <PartnerReservedScheduleModal
        v-if="partner_reserved_schedule_modal"
        :photographer_id="partner_schedule_confirm_photographer_id"
        modal_title="確保済み日程一覧"
        @close="partner_reserved_schedule_modal = false;"
    ></PartnerReservedScheduleModal>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import Partner from '@/models/entities/partner';
import PartnerRank from '@/models/enums/partner/partner-rank'
import ButtonGoCreate from '@/components/buttons/ButtonGoCreate';
import ButtonSearch from '@/components/buttons/ButtonSearch';
import ButtonGoUpdate from '@/components/buttons/ButtonGoUpdate';
import ButtonGoView from '@/components/buttons/ButtonGoView';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import InlineLoader from '@/components/tools/InlineLoader';
import Paginator from '@/components/tools/Paginator';
import AlertNoRecord from '@/components/tools/AlertNoRecord';
import PartnerReservedScheduleModal from '@/components/schedule/PartnerReservedScheduleModal';


export default {
    name: 'PartnerList',
    components: {
        PageTitle,
        ButtonGoCreate,
        ButtonSearch,
        ButtonGoUpdate,
        ButtonGoView,
        FormInput,
        FormSelect,
        InlineLoader,
        Paginator,
        AlertNoRecord,
        PartnerReservedScheduleModal,
    },
    data() {
        return {
            // 応援カメラマン
            partners: [],

            // 検索
            partner_ranks: PartnerRank.options(),
            loading: 0,
            condition: {
                photographer_name: null,
                partner_rank: null,
                page: 1,
            },
            paginator: {},

            // 応援カメラマン予約確認モーダル
            partner_reserved_schedule_modal: false,
            partner_schedule_confirm_photographer_id: null,
        }
    },
    mounted() {
        this.condition = this.$store.getters['condition/merge']('PartnerList', this.condition);
        this.search();
    },
    methods: {
        //検索
        search(page = 1) {
            this.loading++;
            this.condition.page = page;

            //表示データ初期化
            this.partners.splice(0);

            this.$http.get('/partners', {
                params: this.condition
            })
            .then(response => {

                for (let row of response.data.data) {
                    this.partners.push(new Partner(row));
                }
                //ページネーション用
                this.paginator = response.data.meta;
                //検索条件を保存
                this.$store.commit('condition/setSearchCondition', {
                    page: 'PartnerList',
                    condition: this.condition
                });
            })
            .finally(() => {
                this.loading--;
            });
        },

        // 応援カメラマン予約確認モーダルオープン
        openParentReservedScheduleModal(photographer_id) {
            this.partner_schedule_confirm_photographer_id = photographer_id;
            this.partner_reserved_schedule_modal = true;
        }
    }
}
</script>

<style scoped>

</style>
